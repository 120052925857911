import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "userData",
  initialState: {
    user: { id: "", name: "", description: "", jobtitle: "" },
    login: false,
  },
  reducers: {
    loginUser: (state, action) => {
      state.user = action.payload;
      state.user.jobtitle = "Usuario Grammatikos";
      state.login = true;
    },
    logoutUser: (state) => {
      state.user = { id: "", name: "", description: "", jobtitle: "" };
      state.user.jobtitle = "";
      state.login = false;
    },
  },
});

export const { loginUser, logoutUser } = loginSlice.actions;

export default loginSlice.reducer;
