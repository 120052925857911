import React from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { CssBaseline } from "@mui/material";
import ThemeProvider from "theme/ThemeProvider";

import Rutas from "router";

function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Rutas />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
