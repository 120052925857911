import React from "react";
import Avatar from '@mui/material/Avatar';

import { useSelector } from 'react-redux';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  const c = name.split(" ");
  let child = [];
  if (c.length < 2) {
    child.push(name.substring(0,1));
  } else {
    child = c[0].substring(0,1)+c[1].substring(0,1);
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    // children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    children: `${child}`,
  };
}

export default function UserAvatar() {
   const { user } = useSelector(state => state.loginReducer);
  return <Avatar {...stringAvatar(user.description)} />;
}
