import React from "react";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "nprogress/nprogress.css";
import App from "./App";
import { SidebarProvider } from "./contexts/SidebarContext";
import * as serviceWorker from "./serviceWorker";

import { store } from "store/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SidebarProvider>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </SidebarProvider>
  </React.StrictMode>
);

serviceWorker.unregister();
