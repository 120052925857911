import React, { Suspense, lazy, useEffect, useState } from "react";
// import { Navigate } from "react-router-dom";

import SidebarLayout from "./layouts/SidebarLayout";
import BaseLayout from "./layouts/BaseLayout";

import SuspenseLoader from "components/SuspenseLoader";

import { Routes, Route } from "react-router-dom";

import { useDispatch } from "react-redux";
import { actMenuQueries, actMenuProcesos } from "store/menuSlice";

import axios from "axios";

import { useSelector } from "react-redux";

export default function Rutas() {
  const [menu, setMenu] = useState([]);
  const [menuProc, setMenuProc] = useState([]);

  const { user, login } = useSelector((state) => state.loginReducer);
  const { menuProcesos, menuQueries } = useSelector((state) => state.menuReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    if (login) {
      axios
        .get("/api/menu", {
          params: { iduser: user.id },
        })
        .then((res) => {
          if (mounted) {
            const r = res.data.menu;
            setMenu(r);
            if (r.length > 0) {
              dispatch(actMenuQueries());
            }
          }
        })
        .catch();
      axios
        .get("/api/menu-procesos", {
          params: { iduser: user.id },
        })
        .then((res) => {
          if (mounted) {
            const r = res.data.menu;
            setMenuProc(r);
            if (r.length > 0) {
              dispatch(actMenuProcesos());
            }
          }
        })
        .catch();
      return function cleanup() {
        mounted = false;
      };
    }
  }, [user]);

  const ritems = menu.map((mnu) => (
    <Route
      key={mnu}
      type="collapse"
      path={mnu}
      element={<Reportes title={mnu} />}
    />
  ));

  const ritemsProcesos = menuProc.map((mnup) => (
    <Route
      key={mnup}
      type="collapse"
      path={mnup}
      element={<Procesos title={mnup} />}
    />
  ));

  // const gitems = menu.map((mnu) => (
  //   <Route
  //     key={mnu}
  //     type="collapse"
  //     path={mnu}
  //     element={<Grillas title={mnu} />}
  //   />
  // ));

  function GRoute(key, path, element, items, crear) {
    if (crear) {
      return (
        <Route key={key} path={path} element={element}>
          {items}
        </Route>
      );
    } else {
      return;
    }
  }

  const routes = (
    <Routes>
      <Route key="base" element={<BaseLayout />}>
        <Route key="overview" path="/" element={<Overview />} />
      </Route>
      <Route key="inicio" path="inicio" element={<SidebarLayout />}>
        <Route path="grammatikos" element={<Inicio />} />
      </Route>
      <Route key="reports" path="reports" element={<SidebarLayout />}>
        {ritems}
      </Route>
      {GRoute(
        "reports",
        "reports",
        <SidebarLayout />,
        ritems,
        menuQueries
      )}
      {GRoute(
        "process",
        "process",
        <SidebarLayout />,
        ritemsProcesos,
        menuProcesos
      )}
      {/* <Route key="process" path="process" element={<SidebarLayout />}>
        {ritemsProcesos}
      </Route> */}
      {/* <Route key="grids" path="grids" element={<SidebarLayout />}>
        {gitems}
      </Route> */}
    </Routes>
  );

  return routes;
}

// eslint-disable-next-line react/display-name
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import("./content/overview")));

const Inicio = Loader(lazy(() => import("./content/applications/Inicio")));

const Reportes = Loader(lazy(() => import("./content/applications/Reportes")));

const Procesos = Loader(lazy(() => import("./content/applications/Procesos")));

// const Grillas = Loader(lazy(() => import("./content/applications/Grillas")));

// const DashBoard = Loader(lazy(() => import("./content/applications/DashBoard")));

// const Modals = Loader(lazy(() => import("./content/pages/Components/Modals")));

// const routes = [
//   {
//     path: "",
//     element: <BaseLayout />,
//     children: [
//       {
//         path: "/",
//         element: <Overview />,
//       },
//       {
//         path: "overview",
//         element: <Navigate to="/" replace />,
//       },
//       {
//         path: "status",
//         children: [
//           {
//             path: "",
//             element: <Navigate to="404" replace />,
//           },
//           {
//             path: "404",
//             element: <Status404 />,
//           },
//           {
//             path: "500",
//             element: <Status500 />,
//           },
//           {
//             path: "maintenance",
//             element: <StatusMaintenance />,
//           },
//           {
//             path: "coming-soon",
//             element: <StatusComingSoon />,
//           },
//         ],
//       },
//       {
//         path: "*",
//         element: <Status404 />,
//       },
//     ],
//   },
//   {
//     path: "dashboards",
//     element: <SidebarLayout />,
//     children: [
//       {
//         path: "",
//         element: <Navigate to="tasks" replace />,
//       },
//       {
//         path: "crypto",
//         element: <Crypto />,
//       },
//       {
//         path: "messenger",
//         element: <Messenger />,
//       },
//     ],
//   },
//   {
//     path: "management",
//     element: <SidebarLayout />,
//     children: [
//       {
//         path: "",
//         element: <Navigate to="transactions" replace />,
//       },
//       { getMenu },
//     ],
//   },
//   {
//     path: "profile",
//     children: [
//       {
//         path: "",
//         element: <Navigate to="details" replace />,
//       },
//       {
//         path: "details",
//         element: <UserProfile />,
//       },
//       {
//         path: "settings",
//         element: <UserSettings />,
//       },
//     ],
//   },
//   {
//     path: "/components",
//     element: <SidebarLayout />,
//     children: [
//       {
//         path: "",
//         element: <Navigate to="buttons" replace />,
//       },
//       {
//         path: "buttons",
//         element: <Buttons />,
//       },
//       {
//         path: "modals",
//         element: <Modals />,
//       },
//       {
//         path: "accordions",
//         element: <Accordions />,
//       },
//       {
//         path: "tabs",
//         element: <Tabs />,
//       },
//       {
//         path: "badges",
//         element: <Badges />,
//       },
//       {
//         path: "tooltips",
//         element: <Tooltips />,
//       },
//       {
//         path: "avatars",
//         element: <Avatars />,
//       },
//       {
//         path: "cards",
//         element: <Cards />,
//       },
//       {
//         path: "forms",
//         element: <Forms />,
//       },
//     ],
//   },
// ];
