/* eslint-disable react/jsx-key */
import React, { useContext, useState, useEffect } from "react";

import { Button, ListItem } from "@mui/material";
import TableChartTwoToneIcon from "@mui/icons-material/TableChartTwoTone";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "contexts/SidebarContext";
import axios from "axios";

import { useSelector } from "react-redux";

export default function MenuQuery() {
  const { closeSidebar } = useContext(SidebarContext);
  const [menu, setMenu] = useState([]);

  const { user, login } = useSelector((state) => state.loginReducer);

  useEffect(() => {
    let mounted = true;
    if (login) {
      axios
        .get("/api/menu", {
          params: { iduser: user.id },
        })
        .then((res) => {
          if (mounted) {
            const r = res.data.menu;
            setMenu(r);
          }
        })
        .catch();
      return function cleanup() {
        mounted = false;
      };
    }
  }, [login]);

  const menuReport = menu.map((mnu) => (
    <ListItem key={mnu} component="div">
      <Button
        disableRipple
        component={RouterLink}
        onClick={closeSidebar}
        to={"/reports/" + mnu}
        startIcon={<TableChartTwoToneIcon />}
      >
        {mnu}
      </Button>
    </ListItem>
  ));

  return menuReport;
}
