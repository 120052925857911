import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
  name: "menuData",
  initialState: {
    menuProcesos: false,
    menuQueries: false
  },
  reducers: {
    actMenuProcesos: (state) => {
      state.menuProcesos = true;
    },
    actMenuQueries: (state) => {
      state.menuQueries = true;
    },
  },
});

export const { actMenuQueries, actMenuProcesos } = menuSlice.actions;

export default menuSlice.reducer;
